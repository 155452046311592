import { useAsyncPrivateAPI } from "~/uses/useMyFetch";
import { getLocalizedName } from "~/utils/localizedValues";

export const useContentfulStore = defineStore({
  id: "contentfulStore",
  state: () => {
    return {
      homeSlider: [],
      blogPosts: [],
      underFilterSlider: [],
      installationWorkPosts: [],
      faq: {
        items: [],
        navigation: [],
      },
    };
  },
  actions: {
    async fetchHomeSlider() {
      const { data } = await useAsyncPrivateAPI("/entries", {
        params: {
          content_type:
            useRuntimeConfig().public.contentful.contentType.homeSlider,
        },
      });

      this.homeSlider = data.sort((a, b) => a.position - b.position);
    },
    async fetchUnderFilterSlider() {
      const { data } = await useAsyncPrivateAPI("/entries", {
        params: {
          content_type:
            useRuntimeConfig().public.contentful.contentType.underFilterSlider,
          order: "fields.position",
        },
      });

      this.underFilterSlider = data;
    },
    async fetchFaqList(query = {}) {
      const { data } = await useAsyncPrivateAPI("/entries", {
        params: {
          content_type:
            useRuntimeConfig().public.contentful.contentType.faqList,
          ...query,
        },
      });

      this.faq.items = data;
    },
    async fetchFaqNavigation() {
      const { data } = await useAsyncPrivateAPI("/entries", {
        params: {
          content_type:
            useRuntimeConfig().public.contentful.contentType.faqNavigation,
          "fields.siteName": useRuntimeConfig().public.contentful.siteName,
        },
      });

      this.faq.navigation = data;
    },
    async fetchBlogPosts(query = {}) {
      const { data } = await useAsyncPrivateAPI("/entries", {
        params: {
          content_type: useRuntimeConfig().public.contentful.contentType.blog,
          order: "-fields.created",
          ...query,
        },
      });

      this.blogPosts = data;
    },
    async fetchBlogPost(query = {}) {
      if (
        !this.blogPosts.some(
          ({ url }) => getLocalizedName(url) === query["fields.url"],
        )
      ) {
        const { data } = await useAsyncPrivateAPI("/entries", {
          params: {
            content_type: useRuntimeConfig().public.contentful.contentType.blog,
            ...query,
          },
        });

        data.forEach((dto) => this.blogPosts.push(dto));
      }
    },
    async fetchInstallationWorkPosts(query = {}) {
      const { data } = await useAsyncPrivateAPI("/entries", {
        params: {
          content_type:
            useRuntimeConfig().public.contentful.contentType.installationWork,
          order: "-fields.created",
          ...query,
        },
      });

      this.installationWorkPosts = data;
    },
    async fetchInstallationWorkPost(query) {
      if (
        !this.installationWorkPosts.some(
          ({ url }) => getLocalizedName(url) === query["fields.url"],
        )
      ) {
        const { data } = await useAsyncPrivateAPI("/entries", {
          params: {
            content_type:
              useRuntimeConfig().public.contentful.contentType.installationWork,
            ...query,
          },
        });

        data.forEach((dto) => this.installationWorkPosts.push(dto));
      }
    },
  },
  getters: {
    getBlogPost: (state) => {
      return (slug) =>
        state.blogPosts.find(({ url }) => getLocalizedName(url) === slug);
    },
    getInstallationWorkPost: (state) => {
      return (slug) =>
        state.installationWorkPosts.find(
          ({ url }) => getLocalizedName(url) === slug,
        );
    },
  },
});
